import React from 'react'
import Layout from '../layout/layout'

const NotFoundPage = () => (
  <Layout cssClass="page-404">
    <div id="main-content">
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8 mx-auto text-center">
              <h1>404 NOT FOUND</h1>
              <p>
                It looks like the page you were looking for has either moved or no longer exists. Please use the
                navigation above to navigate the site or click the logo to go back home
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
